import { graphql } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import type { PageProps } from 'gatsby'
import type { HomePageQueryQuery } from '@generated/graphql'
import RenderCMS from 'src/components/RenderCMS'
import { mark } from 'src/sdk/tests/mark'

import { storeUrl } from '../../store.config'

export type Props = PageProps<HomePageQueryQuery>

function Page(props: Props) {
  const {
    data: { site, cmsHome },
    location: { pathname, host },
  } = props

  const title = 'SexShop, Linha Noite e Lingerie no Atacado e Varejo'
  const description =
    'Seus produtos favoritos de SexShop, Linha Noite, Lingerie e Moda Praia no Atacado e Varejo Miess | 10X sem Juros | Frete Grátis acima de 250 reais | entrega mesmo dia São Paulo'

  const siteUrl =
    host !== undefined
      ? `https://${host}${pathname.replace(/\/$/, '')}`
      : pathname

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        language="pt-br"
        canonical={storeUrl}
        openGraph={{
          type: 'website',
          url: siteUrl,
          title: title ?? '',
          description: site?.siteMetadata?.description ?? '',
        }}
        metaTags={[
          {
            name: 'msvalidate.01',
            content: 'B2018FF177C1E016CDB25B4C7ECF3B22',
          },
        ]}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: siteUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${siteUrl}/s/?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
          name: site?.siteMetadata?.titleTemplate?.split('|')[1].trim() ?? '',
          logo: 'http://www.miess.vtexassets.com/arquivos/logo-miess-new.png?v=637776758194570000',
          sameAs: [
            'https://twitter.com/miessmodaintima',
            'https://www.facebook.com/miessloja',
            'https://instagram.com/miessmodaintima',
            'https://www.youtube.com/channel/UCSrRELkt-IX8vw9CyYcr4jw/videos',
          ],
        }}
      />

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      <RenderCMS
        sections={cmsHome?.sections}
        extraProps={{
          Newsletter: {
            lazyBackground: false,
          },
        }}
      />
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const querySSG = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
      }
    }
    cmsHome {
      sections {
        id
        name
        data
      }
    }
  }
`

export async function getServerData() {
  const ONE_YEAR_CACHE = `s-maxage=31536000, stale-while-revalidate`

  return {
    status: 200,
    props: {},
    headers: {
      'cache-control': ONE_YEAR_CACHE,
      'content-type': 'text/html',
    },
  }
}

Page.displayName = 'Page'
export default mark(Page)
